.button-link {
  margin: 0;
  border: none;
  overflow: visible;
  font: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  vertical-align: middle;
  background: none;

  font-weight: normal;
  line-height: 1.5;
  list-style: none;
  white-space: nowrap;
  font-size: 0.875rem;
  cursor: pointer;
  display: block;
  text-decoration: none;
  padding: 0;
  color: #999;

  text-align: left;

  &:focus,
  &:hover {
    outline: none;
    color: #666;
  }
}

.uk-nav .button-link {
  padding: 5px 0;
}

.uk-active .button-link {
  color: #666;
}

.uk-pagination .button-link {
  font-size: inherit;
  line-height: inherit;
}

.min-height-100 {
  min-height: 100%;
}

.uk-width-1-7 {
  width: 14.2857%;
}

.unprintable {
  @media print {
    display: none;
  }
}

.printer-only {
  display: none;

  @media print {
    display: inherit;
  }
}

button.uk-navbar-toggle {
  background: none;
  border: none;
}
