.Filters {
  @media print {
    text-align: center;
  }

  .uk-button-default {
    @media print {
      display: none;
    }
  }

  .uk-inline {
    @media print {
      display: block;
    }
  }
}
