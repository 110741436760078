.LoaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff;
  z-index: 1;
}

.uk-background-secondary .LoaderOverlay,
.uk-offcanvas-bar .LoaderOverlay {
  background: #222;
}
