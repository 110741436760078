.SideBar {
  width: 190px;

  .bottom {
    margin-top: auto;
  }

  @media print {
    display: none;
  }
}
